// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ansia-js": () => import("./../../../src/pages/ansia.js" /* webpackChunkName: "component---src-pages-ansia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inizia-js": () => import("./../../../src/pages/inizia.js" /* webpackChunkName: "component---src-pages-inizia-js" */),
  "component---src-pages-metodo-js": () => import("./../../../src/pages/metodo.js" /* webpackChunkName: "component---src-pages-metodo-js" */),
  "component---src-pages-servizi-js": () => import("./../../../src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */),
  "component---src-pages-su-di-me-js": () => import("./../../../src/pages/su-di-me.js" /* webpackChunkName: "component---src-pages-su-di-me-js" */)
}

